import React from "react";
import { translate } from ".";
import GlitchText from "react-glitch-effect/core/GlitchText";
import { StageSpinner } from "react-spinners-kit";

export const instagramToken =
  "IGQWRPX1k0YzB5X253WWs3WERKMTVaRE5VSTU2ci1CQzhES2tNUVhLc3RuREpwNkNoaUZA5SW5ZAUU04N3FWblNhWjk0enl1TFBLXzJoZAkpqcFBvYUVQZA3lJUmhaWjlobHFXNkI0RWVsRDhISlIwa0JfLUtZAWXdWVjAZD";

export const shippingZoneEurope = [
  "Italy",
  "Germany",
  "Netherlands",
  "France",
  "Spain",
  "Portgual",
  "United Kingdom",
  "Belgium",
  "Finland",
  "Poland",
  "Estonia",
  "Lithuania",
  "Latvia",
  "Slovakia",
  "Czech",
  "Ukraine",
  "Ireland",
];

export const specialAddressCharacters = {
  ä: "a",
  é: "e",
  è: "e",
  ö: "o",
  å: "a",
  ø: "o",
  æ: "a",
};

export const supportedCountriesTrackedShippingBring = [
  "Belgium",
  "Danmark",
  "Czech",
  "Estonia",
  "Germany",
  "Finland",
  "France",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Netherlands",
  "Poland",
  "Slovakia",
  "Sverige",
  "United Kingdom",
];

export const countryCodeMapper = (country) => {
  switch (country) {
    case "Italy":
      return "IT";
    case "Germany":
      return "DE";
    case "Netherlands":
      return "NL";
    case "France":
      return "FR";
    case "Spain":
      return "ES";
    case "Portgual":
      return "PT";
    case "United Kingdom":
      return "UK";
    case "Belgium":
      return "BE";
    case "Finland":
      return "FI";
    case "Poland":
      return "PL";
    case "Estonia":
      return "EE";
    case "Lithuania":
      return "LT";
    case "Latvia":
      return "LV";
    case "Slovakia":
      return "SK";
    case "Czech":
      return "CZ";
    case "Ukraine":
      return "UA";
    case "Ireland":
      return "IE";
    case "Sverige":
      return "SE";
    case "Danmark":
      return "DK";
    case "Japan":
      return "JP";
    default:
      return "NO";
  }
};

export const shippingZoneAmericaOceania = [
  "USA",
  "Canada",
  "Australia",
  "NewZealand",
  "Japan",
];

export const shippingScandinavia = ["Sverige", "Danmark"];

export const spaceToDash = (text) => {
  return text.replace(/\s+/g, "-");
};

export const dashToSpace = (text) => {
  return text.replace(/-/g, " ");
};

export const getHeroProduct = () => {
  let texts = ["patch", "buff", "custom"];
  const randomProduct = texts[Math.floor(Math.random() * texts.length)];
  return randomProduct;
};

export const setHeroContent = (language, product, loadingScrollToProduct) => {
  let heroText = translate(language, "FRONTPAGE_HERO_HEADER_PATCH");
  let buttonText = translate(language, "FRONTPAGE_HERO_BUTTON_TEXT_PATCH");
  let scrollTarget = "#patches";
  if (loadingScrollToProduct) {
    return (
      <div className="hero-content">
        <div className="spinner-bg">
          <StageSpinner loading={loadingScrollToProduct} color="#bcf135" />
        </div>
      </div>
    );
  } else if (product === "xmas") {
    buttonText = "Support Ukraine this holiday";
    heroText = "Merry Xmas you filthy animal".toUpperCase();
    return (
      <div className="hero-content">
        <GlitchText iterationCount={"10"}>
          <h1>{heroText}</h1>
        </GlitchText>
        <button
          onClick={() => {
            window.open("https://www.veteranaidukraine.com/", "_blank");
          }}
          className="hero-button"
        >
          {buttonText}
        </button>
      </div>
    );
  } else if (product === "buff") {
    buttonText = translate(language, "FRONTPAGE_HERO_BUTTON_TEXT_BUFF");
    heroText = translate(language, "FRONTPAGE_HERO_HEADER_BUFF");
    scrollTarget = "#gadgets";
  } else if (product === "custom") {
    buttonText = translate(language, "FRONTPAGE_HERO_BUTTON_TEXT_CUSTOM");
    heroText = translate(language, "FRONTPAGE_HERO_HEADER_CUSTOM");
    return (
      <div className="hero-content">
        <GlitchText iterationCount={"10"}>
          <h1>{heroText}</h1>
        </GlitchText>
        <button
          onClick={() => {
            window.open("/customPatches", "_self");
          }}
          className="hero-button"
        >
          {buttonText}
        </button>
      </div>
    );
  }
  return (
    <div className="hero-content">
      <GlitchText iterationCount={"10"}>
        <h1>{heroText}</h1>
      </GlitchText>
      <button
        onClick={() => {
          document.querySelector(scrollTarget).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }}
        className="hero-button"
      >
        {buttonText}
      </button>
    </div>
  );
};

export const hasChangedShippingZones = (oldValue, newValue) => {
  let shippingZonesHasChanged = false;
  if (
    (oldValue === "Norge" && newValue !== "Norge") ||
    (oldValue !== "Norge" && newValue === "Norge")
  ) {
    shippingZonesHasChanged = true;
  } else if (
    (shippingZoneEurope.includes(oldValue) &&
      !shippingZoneEurope.includes(newValue)) ||
    (!shippingZoneEurope.includes(oldValue) &&
      shippingZoneEurope.includes(newValue))
  ) {
    shippingZonesHasChanged = true;
  } else if (
    (shippingScandinavia.includes(oldValue) &&
      !shippingScandinavia.includes(newValue)) ||
    (!shippingScandinavia.includes(oldValue) &&
      shippingScandinavia.includes(newValue))
  ) {
    shippingZonesHasChanged = true;
  } else if (
    (shippingZoneAmericaOceania.includes(oldValue) &&
      !shippingZoneAmericaOceania.includes(newValue)) ||
    (!shippingZoneAmericaOceania.includes(oldValue) &&
      shippingZoneAmericaOceania.includes(newValue))
  ) {
    shippingZonesHasChanged = true;
  }
  return shippingZonesHasChanged;
};

export const renderAmountDecimals = (selectedCurrency) => {
  let decimals = ",00 ";
  if (
    selectedCurrency === "USD" ||
    selectedCurrency === "GBP" ||
    selectedCurrency === "EUR"
  ) {
    decimals = ".00 ";
  }
  return decimals;
};

export const restrictedProductIds = [
  "5e11d0fc7f7e95435d825d43",
  "5ee2b34180782800170d5fd0",
  "5ee2b43a80782800170d5fd3",
  "602170c76dcebb001756ca1d",
  "5f481c660dbddfa1ed818319",
  "5f4a61a432027300170de64d",
  "5f4a621932027300170de650",
  "5fd4ab5d769398001749c360",
  "5fd4ab96769398001749c363",
  "5fd4abeb769398001749c366",
  "6003ff34a7fab30017ea46a5",
  "60da1fb1cb17370017ed969f",
  "61a698ef4d4a330018b22076",
  "61acc91c4604660018fd7705",
  "61671511f3f4a10018fed42a",
  "61671261f3f4a10018fed427",
  "626bf6ad56f1870018047b98",
];

export const sizedProductIds = [
  "6427ace83ae7e1001417e94d",
  "6427a68e3ae7e1001417e938",
  "64e510bd0745be001453f48a",
  "654501b430f71c00142362bc",
  "654503f430f71c00142362bd",
  "65abb6eec27d670014c2566f",
  "65a6d83390b2c53b2317195a",
  "65aac671f4591700148abb6a",
  "65abc4aec27d670014c25671",
  "6661a762000a630015ced2b3",
];

export const showExtendedShippingOptions = (
  country,
  language,
  sendAbroad,
  paymentWalletType
) => {
  if (country !== "Norge") {
    return false;
  }
  return true;
};

export const showExtendedPaymentOptions = (
  country,
  language,
  sendAbroad,
  paymentWalletType
) => {
  if (country === "Norge" || country === "Sverige" || country === "Danmark") {
    return true;
  } else if (language !== "no" && country !== "Norge" && !paymentWalletType) {
    return false;
  }
  return true;
};

export const showVippsPaymentOption = (country, language, sendAbroad) => {
  if (
    country === "Norge" ||
    country === "Sverige" ||
    country === "Danmark" ||
    sendAbroad
  ) {
    return true;
  }
  return false;
};

export const cartContainsHeavyItems = (items) => {
  const heavyItemId = items.findIndex((item) => item.WeightInKilos > 0.1);
  return heavyItemId !== -1;
};

export const cartContainsManyItems = (items) => {
  const multipleConstant = 7;
  const multipleOfSameItem = items.findIndex(
    (item) => item.quantity > multipleConstant
  );
  if (items.length > multipleConstant) {
    return true;
  } else if (multipleOfSameItem !== -1) {
    return true;
  }
};

export const cartContainsLargeItems = (items) => {
  const largeItemId = items.findIndex(
    (item) => item.packageSize === "large" || item.packageSize === "xlarge"
  );
  return largeItemId !== -1;
};

export const cartContainsMultipleHeavyItems = (items) => {
  const heavyItems = items.filter((item) => item.WeightInKilos > 0.1);
  // check if cart contains multiple different heavy items
  if (heavyItems.length > 1) {
    return true;
  }
  // check if cart contains multiple of the same heavy item
  return heavyItems.findIndex((item) => item.quantity > 1) !== -1;
};

export const cartContainsMultipleLargeItems = (items) => {
  const largeItems = items.filter(
    (item) => item.packageSize === "large" || item.packageSize === "xlarge"
  );
  // check if cart contains multiple different heavy items
  if (largeItems.length > 1) {
    return true;
  }
  // check if cart contains multiple of the same heavy item
  return largeItems.findIndex((item) => item.quantity > 1) !== -1;
};
