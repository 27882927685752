import { translate, calculateAmount, jegerbueId } from ".";
import { FREE_SHIPPING_NORWAY } from "./translations";
import React from "react";

export const cartButtonText = (
  sizesSelected,
  websiteLanguage,
  product,
  qualificationDisclaimerAccepted
) => {
  let sizedProduct = null;
  if (product.sizes && Object.keys(product.sizes).length !== 0) {
    sizedProduct = product.sizes;
  } else if (product.types && Object.keys(product.types).length !== 0) {
    sizedProduct = product.types;
  }
  if (product.itemsLeft < 1) {
    return translate(websiteLanguage, "FRONTPAGE_OUT_OF_STOCK");
  } else {
    if (sizedProduct && sizesSelected.length === 0) {
      return translate(
        websiteLanguage,
        product.sizes
          ? "FRONTPAGE_ADD_CHOOSE_SIZE"
          : "FRONTPAGE_ADD_CHOOSE_TYPE"
      );
    } else if (sizedProduct && sizesSelected.length > 0) {
      const sizeSelected = sizesSelected.findIndex(
        (item) => item.productId === product._id
      );
      if (sizeSelected === -1) {
        return translate(
          websiteLanguage,
          product.sizes
            ? "FRONTPAGE_ADD_CHOOSE_SIZE"
            : "FRONTPAGE_ADD_CHOOSE_TYPE"
        );
      } else {
        return translate(websiteLanguage, "FRONTPAGE_ADD_TO_CART");
      }
    } else if (product._id === jegerbueId && !qualificationDisclaimerAccepted) {
      return translate(websiteLanguage, "FRONTPAGE_MORE_INFO");
    } else {
      return translate(websiteLanguage, "FRONTPAGE_ADD_TO_CART");
    }
  }
};

export const itemsInCart = (items) => {
  return Number(items.reduce((acc, item) => acc + item.quantity, 0));
};

export const renderFreeShippingText = (
  customOrder,
  websiteLanguage,
  cartItems
) => {
  let text = translate(websiteLanguage, "SHIPPING_FREE_AMOUNT");
  if (customOrder) {
    text = translate(websiteLanguage, "SHIPPING_FREE");
  } else {
    if (calculateAmount(cartItems, false) >= FREE_SHIPPING_NORWAY) {
      text = translate(websiteLanguage, "SHIPPING_FREE");
    }
  }
  return <div className="cart-shipping">{text}</div>;
};

export const setCampaignLabel = (campaign, websiteLanguage) => {
  let campaignText;
  switch (campaign) {
    case "intro_offer":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN1");
      break;
    case "new":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN2");
      break;
    case "new_price":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN3");
      break;
    case "limited_stock":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN4");
      break;
    case "sale":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN5");
      break;
    case "preview":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN6");
      break;
    case "special":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN7");
      break;
    case "blackout":
      campaignText = translate(websiteLanguage, "FRONTPAGE_PATCHES_CAMPAIGN8");
      break;
    default:
      return null;
  }
  return <div className="offer-price">{campaignText}</div>;
};

export const mapToCartSize = (selectedSize) => {
  if (selectedSize === "small") {
    return "(S)";
  } else if (selectedSize === "medium") {
    return "(M)";
  } else if (selectedSize === "large") {
    return "(L)";
  } else if (selectedSize === "xlarge") {
    return "(XL)";
  } else if (selectedSize === "OR2" || selectedSize === "0_OR2_COYOTE") {
    return "(OR2)";
  } else if (selectedSize === "OR3" || selectedSize === "6_OR3_COYOTE") {
    return "(OR3)";
  } else if (selectedSize === "OR4" || selectedSize === "7_OR4_COYOTE") {
    return "(OR4)";
  } else if (selectedSize === "OR4P" || selectedSize === "8_OR4P_COYOTE") {
    return "(OR4+)";
  } else if (selectedSize === "OR5" || selectedSize === "1_OR5_COYOTE") {
    return "(OR5)";
  } else if (selectedSize === "OR5P" || selectedSize === "2_OR5P_COYOTE") {
    return "(OR5+)";
  } else if (selectedSize === "OR6" || selectedSize === "9_OR6_COYOTE") {
    return "(OR6)";
  } else if (selectedSize === "OR6S" || selectedSize === "3_OR6S_COYOTE") {
    return "(OR6 Sjef)";
  } else if (selectedSize === "OR7") {
    return "(OR7)";
  } else if (selectedSize === "OR7S") {
    return "(OR7 Sjef)";
  } else if (selectedSize === "OR8") {
    return "(OR8)";
  } else if (selectedSize === "OR8S") {
    return "(OR8 Sjef)";
  } else if (selectedSize === "4_OF1_COYOTE") {
    return "(OF1)";
  } else if (selectedSize === "5_OF2_COYOTE") {
    return "(OF2)";
  } else if (selectedSize === "OR9") {
    return "(OR9)";
  } else if (selectedSize === "OR9S") {
    return "(OR9 Sjef)";
  } else if (selectedSize === "NS") {
    return "(Black)";
  } else if (selectedSize === "NG") {
    return "(Olive)";
  } else if (selectedSize === "KS") {
    return "(Black)";
  } else if (selectedSize === "KG") {
    return "(Olive)";
  } else if (selectedSize === "Black") {
    return "(Black)";
  } else if (selectedSize === "Olive") {
    return "(Olive)";
  } else if (selectedSize === "Coyote") {
    return "(Coyote)";
  } else if (selectedSize === "Multicam") {
    return "(Multicam)";
  } else if (selectedSize === "Normal") {
    return "(Normal)";
  } else if (selectedSize === "Reverse") {
    return "(Reverse)";
  } else if (selectedSize === "Aplus") {
    return "(A+)";
  } else if (selectedSize === "Aminus") {
    return "(A-)";
  } else if (selectedSize === "ABplus") {
    return "(AB+)";
  } else if (selectedSize === "ABminus") {
    return "(AB-)";
  } else if (selectedSize === "Bplus") {
    return "(B+)";
  } else if (selectedSize === "Bminus") {
    return "(B-)";
  } else if (selectedSize === "0plus") {
    return "(0+)";
  } else if (selectedSize === "0minus") {
    return "(0-)";
  } else if (selectedSize === "SM") {
    return "(S/M)";
  } else if (selectedSize === "LXL") {
    return "(L/XL)";
  } else {
    return null;
  }
};

export const mapToSelectOptionSize = (selectedSize) => {
  if (selectedSize === "small") {
    return "S";
  } else if (selectedSize === "medium") {
    return "M";
  } else if (selectedSize === "large") {
    return "L";
  } else if (selectedSize === "xlarge") {
    return "XL";
  } else if (selectedSize === "OR2" || selectedSize === "0_OR2_COYOTE") {
    return "OR2";
  } else if (selectedSize === "OR3" || selectedSize === "6_OR3_COYOTE") {
    return "OR3";
  } else if (selectedSize === "OR4" || selectedSize === "7_OR4_COYOTE") {
    return "OR4";
  } else if (selectedSize === "OR4P" || selectedSize === "8_OR4P_COYOTE") {
    return "OR4+";
  } else if (selectedSize === "OR5" || selectedSize === "1_OR5_COYOTE") {
    return "OR5";
  } else if (selectedSize === "OR5P" || selectedSize === "2_OR5P_COYOTE") {
    return "OR5+";
  } else if (selectedSize === "OR6" || selectedSize === "9_OR6_COYOTE") {
    return "OR6";
  } else if (selectedSize === "OR6S" || selectedSize === "3_OR6S_COYOTE") {
    return "OR6 Sjef";
  } else if (selectedSize === "4_OF1_COYOTE") {
    return "OF1";
  } else if (selectedSize === "5_OF2_COYOTE") {
    return "OF2";
  } else if (selectedSize === "OR7") {
    return "OR7";
  } else if (selectedSize === "OR7S") {
    return "OR7 Sjef";
  } else if (selectedSize === "OR8") {
    return "OR8";
  } else if (selectedSize === "OR8S") {
    return "OR8 Sjef";
  } else if (selectedSize === "OR9") {
    return "OR9";
  } else if (selectedSize === "OR9S") {
    return "OR9 Sjef";
  } else if (selectedSize === "NS") {
    return "Black";
  } else if (selectedSize === "NG") {
    return "Olive";
  } else if (selectedSize === "KS") {
    return "Black";
  } else if (selectedSize === "KG") {
    return "Olive";
  } else if (selectedSize === "Black") {
    return "Black";
  } else if (selectedSize === "Olive") {
    return "Olive";
  } else if (selectedSize === "Coyote") {
    return "Coyote";
  } else if (selectedSize === "Multicam") {
    return "Multicam";
  } else if (selectedSize === "Normal") {
    return "Normal";
  } else if (selectedSize === "Reverse") {
    return "Reverse";
  } else if (selectedSize === "Aplus") {
    return "A+";
  } else if (selectedSize === "Aminus") {
    return "A-";
  } else if (selectedSize === "ABplus") {
    return "AB+";
  } else if (selectedSize === "ABminus") {
    return "AB-";
  } else if (selectedSize === "Bplus") {
    return "B+";
  } else if (selectedSize === "Bminus") {
    return "B-";
  } else if (selectedSize === "0plus") {
    return "0+";
  } else if (selectedSize === "0minus") {
    return "0-";
  } else if (selectedSize === "SM") {
    return "S/M";
  } else if (selectedSize === "LXL") {
    return "L/XL";
  } else {
    return null;
  }
};
