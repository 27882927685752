export const dummyProduct = {
  _id: "dummeproduct",
  description: "dummy product",
  englishDescription: "dummy product",
  image: {
    url: "https://www.3charlie.no/icons/3charlie.svg"
  },
  itemsLeft: 100,
  label: "",
  name: "Dummy product",
  price: "3"
};

export const showTestProduct = false;
export const showRankPatches = true;
export const showAdhesives = false;

export const testProductRankPatch = [{
  _id: "test123",
  danishDescription: null,
  description: "Test product description",
  englishDescription: "Test product description",
  image: {
    url: "https://sjanten.s3.eu-central-1.amazonaws.com/177df0d41e694868a842c479e7adc54a.jpg"
  },
  imageGallery: {
    url: "https://www.3charlie.no/icons/3charlie.svg"
  },
  itemsLeft: 5,
  label: undefined,
  name: "Borrelås distinksjon",
  price: 500,
  types: {
    "OR2": 0,
    "OR3": 1,
    "OR4": 2,
    "OR4P": 3,
    "OR5": 4,
    "OR5P": 5,
    "OR6": 6,
    "OR6S": 0,
    "OR7": 7,
    "OR7S": 8,
    "OR8": 0,
    "OR8S": 0,
    "OR9": 0,
    "OR9S": 9,
  },
  swedishDescription: null
}];

export const testProductAdhesive = [{
  _id: "test123",
  danishDescription: null,
  description: "Test product description",
  englishDescription: "Test product description",
  image: {
    url: "https://sjanten.s3.eu-central-1.amazonaws.com/177df0d41e694868a842c479e7adc54a.jpg"
  },
  imageGallery: {
    url: "https://www.3charlie.no/icons/3charlie.svg"
  },
  itemsLeft: 5,
  label: undefined,
  name: "Drop Table",
  price: 80,
  types: {
    "NS": 1,
    "NG": 1,
    "KS": 1,
    "KG": 1,
  },
  swedishDescription: null
}];