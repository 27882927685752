import React from "react";
import { translate } from "../utils";
import { NavLink } from "react-router-dom";

const Navbar = (props) => (
  <header
    className={`site-header ${props.transparent ? "transparent" : "opaque"}`}
  >
    {props.showBackButton && (
      <NavLink to="/" className="navbar-backlink">
          <img
            src="./icons/chevron.svg"
            alt="back button"
            className="navbar-back-button"
          />
          <div className="navbar-back-button-text">
            {translate(props.websiteLanguage, props.backButtonTextRef)}
          </div>
    </NavLink>
    )}
    <img
      className="site-logo"
      alt="logo"
      src="./icons/3charlie.svg"
      onClick={props.showDiscountCode}
    />
    <h1 className="site-name">3CHARLIE</h1>
  </header>
);

export default Navbar;
