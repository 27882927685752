import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "gestalt/dist/gestalt.css";
import App from "./components/App";
import Checkout from "./components/Checkout";
import GiftCard from "./components/GiftCard";
import registerServiceWorker from "./registerServiceWorker";
import "./components/App.scss";
import CustomPatches from "./components/CustomPatches";
import NvgMount from "./components/NvgMount";
import Generic from "./components/Generic";
import Slava from "./components/Slava";
import ProductView from "./components/ProductView";
import ScrollMemory from "react-router-scroll-memory";

const Root = () => (
  <Router>
    <React.Fragment>
      <ScrollMemory />
      <Switch>
        <Route component={(props) => <App {...props} />} exact path="/" />
        <Route component={Checkout} path="/checkout" />
        <Route component={GiftCard} path="/giftcard" />
        <Route component={CustomPatches} path="/custompatches" />
        <Route component={Generic} path="/woot" />
        <Route component={Slava} path="/slavaukraini" />
        <Route component={NvgMount} path="/low-profile-nvg-mount-specs" />
        <Route component={ProductView} path="/:name" />
      </Switch>
    </React.Fragment>
  </Router>
);

ReactDOM.render(<Root />, document.getElementById("root"));
registerServiceWorker();
