import React from "react";
import { returnFormUrl, translate } from "../utils";

const Agreement = (props) => (
  <div
    className={`purchase-agreement ${
      props.showAgreement ? "visible" : "hidden"
    }`}
  >
    <div className="purchase-agreement-content">
      <h1>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT")} 3charlie.no
      </h1>
      <h2>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CONFIRMATION_HEADER"
        )}
      </h2>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CONFIRMATION_TEXT"
        )}{" "}
        <a href="mailto:1@3charlie.no">1@3charlie.no</a>.
      </p>
      <h2>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_PAYMENT_HEADER")}
      </h2>
      <p>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_PAYMENT_TEXT")}
      </p>
      <h2>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_SHIPPING_HEADER")}
      </h2>
      <p>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_SHIPPING_TEXT")}
      </p>
      <h2>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_DELIVERY_HEADER")}
      </h2>
      <p>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_DELIVERY_TEXT")}
      </p>
      <h2>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CANCELLATION_HEADER"
        )}
      </h2>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CANCELLATION_TEXT_1"
        )}{" "}
        <a href="mailto:1@3charlie.no">1@3charlie.no</a>{" "}
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CANCELLATION_TEXT_2"
        )}{" "}
        <a
          href={returnFormUrl(props.websiteLanguage)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {translate(
            props.websiteLanguage,
            "PURCHASE_AGREEMENT_CANCELLATION_LINK"
          )}
        </a>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CANCELLATION_TEXT_3"
        )}
      </p>
      <p>
        3charlie.no
        <br />
        Bjerkealléen 26B
        <br />
        0487 OSLO
        {props.websiteLanguage !== "no" && (
          <React.Fragment>
            <br />
            {translate(props.websiteLanguage, "CHECKOUT_FORM_COUNTRY_NORWAY")}
          </React.Fragment>
        )}
      </p>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CANCELLATION_TEXT_4"
        )}
      </p>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CANCELLATION_TEXT_5"
        )}
      </p>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_CANCELLATION_TEXT_6"
        )}
      </p>

      <h2>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_COMPLAINTS_HEADER"
        )}
      </h2>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_1"
        )}
      </p>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_2"
        )}
      </p>
      <p>
        {translate(
          props.websiteLanguage,
          "PURCHASE_AGREEMENT_COMPLAINTS_TEXT_3"
        )}{" "}
        <a href="mailto:1@3charlie.no">1@3charlie.no</a>.
      </p>

      <h2>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_DISPUTES_HEADER")}
      </h2>
      <p>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_DISPUTES_TEXT")}
      </p>

      <h2>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_PRIVACY_HEADER")}
      </h2>
      <p>
        {translate(props.websiteLanguage, "PURCHASE_AGREEMENT_PRIVACY_TEXT")}{" "}
        <a href="mailto:1@3charlie.no">1@3charlie.no</a>.
      </p>
    </div>
    <div className="purchase-agreement-close-btn-container">
      <button
        className="purchase-agreement-close-btn"
        onClick={() => {
          props.toggleAgreementVisibility();
          document
            .querySelector("html")
            .setAttribute("style", "overflow: visible;");
        }}
      >
        {translate(props.websiteLanguage, "CLOSE")}
      </button>
    </div>
  </div>
);

export default Agreement;
