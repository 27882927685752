import React from "react";
import Navbar from "./Navbar";
import {
  getLanguageFromBrowser,
  getLanguageFromCookie,
  getLanguageFromParams,
} from "../utils";
import "./NvgMount.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import { observer } from "mobx-react";

class NvgMount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      websiteLanguage: "no",
      name: undefined,
      email: undefined,
      description: undefined,
      amount: undefined,
      type: undefined,
      sendingRequest: false,
      requestSubmitted: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    document.querySelector("html").setAttribute("style", "overflow: visible;");

    if (getLanguageFromParams() === "no") {
      this.setState({ websiteLanguage: "no" });
    } else if (getLanguageFromCookie()) {
      if (getLanguageFromCookie() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromCookie() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromCookie() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    } else {
      if (getLanguageFromBrowser() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromBrowser() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromBrowser() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    }
    if (document.querySelector("#root").classList.contains("checkout")) {
      document.querySelector("#root").classList.remove("checkout");
    }
    if (document.querySelector("#root").classList.contains("cart-visible")) {
      document.querySelector("#root").classList.remove("cart-visible");
      document.querySelector("#root").classList.add("cart-hidden");
    }
  }

  render() {
    return (
      <ParallaxProvider>
        <div className="root-container nvg-mount">
          <Navbar
            showBackButton={true}
            websiteLanguage={this.state.websiteLanguage}
            transparent={true}
            backButtonTextRef={"HEADER_ALL_PRODUCTS"}
          />
          <section className="section-nvg-mount">
            <div className="text">
              <h1 className="header">Takk for kjøp av Low Profile NVG Mount</h1>
              <p>Produktet består av to deler: bridge og mount.</p>
              <p>
                Du vil finne en liten pappeske som inneholder bridgen med
                tilbehør. Mounten er pakket inn i plast og det følger med en
                liten umbraco-nøkkel.
              </p>
              <p>Under følger en kort veiledning og instruksjon.</p>
              <h2>Montering og tilpasning</h2>
              <p>
                <span className="bullet-number">1</span>Start med å feste bridge
                til mount via dovetail adapteren.
              </p>
              <p>
                <span className="bullet-number">2</span>Monokkol skrus på hver
                av bridgens J-arm. Husk å skru til godt, slik at monoklene ikke
                tapes/skades.
              </p>
              <p>
                <span className="bullet-number">3</span>Mounten festes til
                shrouden. Husk at låsen på mounten må være deaktivert, slik at
                låsetappen ikke stikker ut.
              </p>
              <p>
                <span className="bullet-number">4</span>Etter at mounten er
                plassert i shrouden på hjelmen, må låsen på mounten aktiveres
                igjen.
              </p>
              <p>
                <span className="bullet-number">5</span>Vurder om du ønsker å
                bruke break-away funksjonalitet eller ikke. Dersom break-away er
                aktivert, vil mounten lettere løsne fra shrouden.
              </p>
              <p>
                <span className="bullet-number">6</span>Fest bungees til mount
                eller monokler.
              </p>
              <p>
                <span className="bullet-number">7</span>Sett hjelmen på hodet og
                tilpass monoklene med justeringsmulighetene nærmere beskrevet
                under.
              </p>
              <p>
                <span className="bullet-number">8</span>Bruk medfølgende
                umbraco-nøkkel til å justere friksonsskruene. Dette er viktig
                for å fastsette rett motstand i j-arms, og vil sørge for at
                monoklene ikke skyves ut av posisjon dersom de utilsiktet støter
                borti objekter.
              </p>
              <p>
                <span className="bullet-number">9</span>Det følger med to typer
                avstandsskruer til bridgen: store skruer for bedre taktilitet og
                små skruer for å spare vekt. Avstandsskruer er valgfrie å bruke
                og vil øke vekten på montasjen marginalt. Hensikten med
                avstandsskruene er å kunne sette en fast avstand mellom
                monoklene, slik at de enkelt kan dyttes på plass med riktig
                avstand mellom øynene. Man kan med fordel bruke en mutter
                (medfølger ikke) for å feste avstandsskruene.
              </p>
              <p>
                <span className="bullet-number">10</span>På undersiden av
                bridgen er det tre skruer som er festet til dovetail-adapteren.
                Disse er det viktig å etterstramme jevnlig med umbraco-nøkkel
                som medfølger. Alternativt kan man bruke Loctite e.l. for å lime
                fast skruene og for at disse ikke løsner utilsiktet.
              </p>
              <p>
                Den beste måten å midlertidig flytte nattoptikken vekk fra
                øynene, er å dytte monoklene opp og til hver side, slik at de
                plasseres foran hjelmen. Man trenger ikke flippe opp hele
                mounten.
              </p>
              <p>
                NB: Monoklene vil ikke skru seg av automatisk når de flippes
                helt opp. Monoklene må skrus av manuelt.
              </p>
              <p>
                Se bilder under for nærmere beskrivelse av funksjoner og
                delenavn.
              </p>
            </div>
            <img alt="nvg-instructions" src="./icons/nvg-2.jpg"></img>
            <img alt="nvg-instructions" src="./icons/nvg-1.jpg"></img>
            <img alt="nvg-instructions" src="./icons/nvg-3.jpg"></img>
            <img alt="nvg-instructions" src="./icons/nvg-5.jpg"></img>
            <img alt="nvg-instructions" src="./icons/nvg-6.jpg"></img>
            <img alt="nvg-instructions" src="./icons/nvg-4.jpg"></img>
            <p>
              Håper du blir fornøyd{" "}
              <span role="img" aria-label="call me emoji">
                🤙
              </span>
            </p>
            <p>Ikke nøl med å ta kontakt ved spørsmål.</p>
            <div>
              <a href="mailto:3charlie@3charlie.no">3charlie@3charlie.no</a>
            </div>
          </section>
        </div>
      </ParallaxProvider>
    );
  }
}

export default observer(NvgMount);
