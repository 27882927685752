import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import WalletTest from "./WalletTest";

const apiKey =
  process.env.NODE_ENV === "production"
    ? "pk_live_UHeYYH0PiwSmgHUonA6ajtB300nbfL1juS"
    : "pk_test_NOd2qrbXZ580MZsq1PDD095000rfK7Uhll";
const stripePromise = loadStripe(apiKey);

function GiftCard() {
  return (
    <Elements
      stripe={stripePromise}
    >
      <WalletTest />
    </Elements>
  );
}

export default GiftCard;
