import { makeObservable, observable, action, computed } from "mobx"

class ProductsStore {
    products = [];
    campaigns = [];

    constructor() {
        makeObservable(this, {
            products: observable,
            addProducts: action,
            allProducts: computed,
            allCampaigns: computed
        })
    }

    addProducts = (product) => {
        this.products.push(product);
    };

    addCampaigns = (campaign) => {
        this.campaigns.push(campaign);
    };

    get allProducts() {
        return this.products;
    }

    get allCampaigns() {
        return this.campaigns;
    }
}

export const productsStore = new ProductsStore();