import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";

const apiKey =
  process.env.NODE_ENV === "production"
    ? "pk_live_UHeYYH0PiwSmgHUonA6ajtB300nbfL1juS"
    : "pk_test_NOd2qrbXZ580MZsq1PDD095000rfK7Uhll";
const stripePromise = loadStripe(apiKey);

function Checkout() {
  return (
    <Elements
      stripe={stripePromise}
      locale="nb"
      options={{
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Allerta+Stencil",
          },
        ],
      }}
    >
      <CheckoutForm />
    </Elements>
  );
}

export default Checkout;
