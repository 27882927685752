import React from "react";
import { translate } from "../utils";
import "./DonationSection.scss";
import { isLongDistanceShipping } from "../utils/shipping";

const DonationSection = (props) => (
  <section
    className={`donation-section ${
      props.validPostalCodeSubmitted || props.sendAbroad ? "active" : "inactive"
    } ${
      (props.sendAbroad && !isLongDistanceShipping(props.country)) ||
      (!props.sendAbroad && isLongDistanceShipping(props.country))
        ? "top-border"
        : "no-top-border"
    }`}
  >
    <div className="donation-section-header">
      {translate(props.websiteLanguage, "CHECKOUT_DONATION_HEADER")}{" "}
      <a
        href="https://www.veteranaidukraine.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Veteran Aid Ukraine
      </a>
    </div>
    <div className="donation-buttons">
      <button
        type="button"
        className={`donation-button ${
          props.donationAmountSelected === 0 ? "selected" : "unselected"
        }`}
        onClick={() => props.donationButtonSelected(0)}
      >
        0
      </button>
      <button
        type="button"
        className={`donation-button ${
          props.donationAmountSelected === 25 ? "selected" : "unselected"
        }`}
        onClick={() => props.donationButtonSelected(25)}
      >
        25 {translate(props.websiteLanguage, "CURRENCY_NORWEGIAN")}
      </button>
      <button
        type="button"
        className={`donation-button ${
          props.donationAmountSelected === 50 ? "selected" : "unselected"
        }`}
        onClick={() => props.donationButtonSelected(50)}
      >
        50 {translate(props.websiteLanguage, "CURRENCY_NORWEGIAN")}
      </button>
      <button
        type="button"
        className={`donation-button ${
          props.donationAmountSelected === 100 ? "selected" : "unselected"
        }`}
        onClick={() => props.donationButtonSelected(100)}
      >
        100 {translate(props.websiteLanguage, "CURRENCY_NORWEGIAN")}
      </button>
      <button
        type="button"
        className={`donation-button ${
          props.donationAmountSelected === 200 ? "selected" : "unselected"
        }`}
        onClick={() => props.donationButtonSelected(200)}
      >
        200 {translate(props.websiteLanguage, "CURRENCY_NORWEGIAN")}
      </button>
    </div>
    <div className="donation-explainer">
      {translate(props.websiteLanguage, "CHECKOUT_DONATION_EXPLAINER")}
    </div>
  </section>
);

export default DonationSection;
