import React from "react";
import Agreement from "./Agreement";
import { translate, languageSwitcher } from "../utils";
import { NavLink } from "react-router-dom";

const year = new Date().getFullYear();

const setLanguageSwitcherStringId = (currentLanguage) => {
  if (currentLanguage === "en") {
    if (languageSwitcher(currentLanguage) === "da") {
      return "SWICTH_LANGUAGE_DANISH";
    } else if (languageSwitcher(currentLanguage) === "sv") {
      return "SWICTH_LANGUAGE_SWEDISH";
    } else {
      return "SWICTH_LANGUAGE_NORWEGIAN";
    }
  } else {
    return "SWICTH_LANGUAGE_ENGLISH";
  }
};

const Footer = (props) => (
  <footer>
    <button
      className="logo-button"
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <img className="site-logo-footer" alt="logo" src="./icons/3charlie.svg" />
    </button>
    <h3>3charlie.{translate(props.language, "WEBSITE_DOMAIN")}</h3>
    <NavLink className="giftcard-link" to="/customPatches">
      {translate(props.language, "CUSTOM_PATCHES_TITLE")}
    </NavLink>
    <div
      role="button"
      className="footer-agreement-button"
      onClick={() => {
        props.toggleAgreementVisibility();
      }}
    >
      {translate(props.language, "PURCHASE_AGREEMENT")}
    </div>
    <div>
      {translate(props.language, "FOOTER_CONTACT")}:{" "}
      <a href="mailto:3charlie@3charlie.no">3charlie@3charlie.no</a>
    </div>
    <Agreement
      showAgreement={props.showAgreement}
      toggleAgreementVisibility={props.toggleAgreementVisibility}
      websiteLanguage={props.language}
    />
    <a
      className="instagram-link"
      href="https://www.instagram.com/3charlie.no/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img alt="instagram logo" src="./icons/instagram-icon.svg" />
      {translate(props.language, "INSTAGRAM_LINK")}
    </a>
    <div className="cookies">
      {translate(props.language, "FOOTER_COOKIES_DISCLAIMER")}
    </div>
    <div className="orgname">
      &copy; {year} - Plux AS - {translate(props.language, "FOOTER_ORG_NUMBER")}{" "}
      920 924 506
    </div>
    <button
      className="language-button"
      onClick={() => props.switchLanguage(languageSwitcher(props.language))}
    >
      {translate(props.language, setLanguageSwitcherStringId(props.language))}
    </button>
  </footer>
);

export default Footer;
