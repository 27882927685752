import React from "react";
import { translate } from "../utils";
import "./HeroLinks.scss";

const HeroLinks = (props) => (
  <div className="menu-links-container">
    {props.showSearchField ? (
      <React.Fragment>
        <input
          id="products-search-field"
          className="search-field"
          placeholder={translate(
            props.websiteLanguage,
            "FRONTPAGE_SEARCH_PLACEHOLDER"
          )}
          onChange={props.searchFieldOnChange}
        ></input>
        <button onClick={props.toggleSearchField}>
          <img src="./icons/close-icon.svg" alt="Close search icon" />
        </button>
      </React.Fragment>
    ) : (
      <React.Fragment>

        <a
          className={`anchor-link ${
            props.apparelLoading ? "loading" : ""
          } hero-link-3`}
          href="#tshirts"
        >
          {translate(props.websiteLanguage, "FRONTPAGE_TSHIRTS_HEADER")}
        </a>

        <a
          className={`anchor-link ${
            props.patchesLoading ? "loading" : ""
          } hero-link-1`}
          href="#patches"
        >
          {translate(props.websiteLanguage, "FRONTPAGE_PATCHES_HEADER")}
        </a>
        <a
          className={`anchor-link ${
            props.buffsLoading ? "loading" : ""
          } hero-link-2`}
          href="#gadgets"
        >
          {translate(props.websiteLanguage, "FRONTPAGE_GADGETS_HEADER")}
        </a>
        <button
          className={`anchor-link search-field-button ${
            props.patchesLoading || props.buffsLoading || props.apparelLoading
              ? "loading"
              : ""
          }`}
          onClick={props.toggleSearchField}
        >
          <img src="./icons/search-icon.svg" alt="Close search icon" />
        </button>
      </React.Fragment>
    )}
  </div>
);

export default HeroLinks;
