import React from "react";
import Navbar from "./Navbar";
import { NavLink } from "react-router-dom";
import {
  getLanguageFromBrowser,
  getLanguageFromCookie,
  getLanguageFromParams,
  validEmailAddress,
  translate,
  getStrapiUrl,
} from "../utils";
import { StageSpinner } from "react-spinners-kit";
import "./CustomPatches.scss";
import { ParallaxProvider } from "react-scroll-parallax";
import Strapi from "strapi-sdk-javascript/build/main";
import { observer } from "mobx-react";
const strapi = new Strapi(getStrapiUrl());

class CustomPatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      websiteLanguage: "no",
      name: undefined,
      email: undefined,
      description: undefined,
      amount: undefined,
      type: undefined,
      product: "patch",
      sendingRequest: false,
      requestSubmitted: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    document.querySelector("html").setAttribute("style", "overflow: visible;");

    if (getLanguageFromParams() === "no") {
      this.setState({ websiteLanguage: "no" });
    } else if (getLanguageFromCookie()) {
      if (getLanguageFromCookie() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromCookie() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromCookie() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    } else {
      if (getLanguageFromBrowser() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromBrowser() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromBrowser() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    }
    if (document.querySelector("#root").classList.contains("checkout")) {
      document.querySelector("#root").classList.remove("checkout");
    }
    if (document.querySelector("#root").classList.contains("cart-visible")) {
      document.querySelector("#root").classList.remove("cart-visible");
      document.querySelector("#root").classList.add("cart-hidden");
    }
  }

  handleSendRequest = async () => {
    this.setState({ sendingReuquest: true });
    try {
      await strapi.createEntry("customrequests", {
        name: this.state.name,
        email: this.state.email,
        description: this.state.description,
        quantity: this.state.amount,
        type: `${this.state.product === "coin" ? "Coin" : "Patch, "}${
          this.state.type ? this.state.type : ""
        }`,
        requestTime: `${new Date(Date.now())}`,
      });
    } catch (e) {
      console.log("feil skjedde");
      this.setState({
        sendingRequest: false,
        errorMessage: translate(
          this.state.websiteLanguage,
          "NOTIFYME_GENERIC_ERROR"
        ),
      });
    }
    this.setState({
      sendingRequest: false,
      requestSubmitted: true,
      errorMessage: null,
    });
  };

  setErrorMessage = (translationId) => {
    this.setState({
      errorMessage: translate(this.state.websiteLanguage, translationId),
    });
  };

  formHasMissingFields() {
    let formHasMissingFields = true;
    if (this.state.name && this.state.name.trim().length) {
      formHasMissingFields = false;
    } else {
      formHasMissingFields = true;
    }
    if (this.state.email && this.state.email.trim().length) {
      formHasMissingFields = false;
    } else {
      formHasMissingFields = true;
    }
    if (this.state.description && this.state.description.trim().length) {
      formHasMissingFields = false;
    } else {
      formHasMissingFields = true;
    }
    if (this.state.type) {
      formHasMissingFields = false;
    } else {
      formHasMissingFields = true;
    }
    if (this.state.amount) {
      formHasMissingFields = false;
    } else {
      formHasMissingFields = true;
    }
    return formHasMissingFields;
  }

  handleSubmitRequest = (e) => {
    e.preventDefault();
    if (this.formHasMissingFields()) {
      this.setErrorMessage("REQUEST_FORM_FIELDS_ERROR");
    } else if (!validEmailAddress(this.state.email)) {
      document.querySelector("#email").classList.add("validation-error");
      this.setErrorMessage("REQUEST_FORM_EMAIL_ERROR");
    } else {
      this.handleSendRequest();
    }
  };

  handleChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFocus = (e) => {
    this.setState({ errorMessage: null });
    if (e.target && e.target.classList.contains("validation-error")) {
      e.target.classList.remove("validation-error");
    }
  };

  renderInfoBox = (typeSelected) => {
    let infoBoxText = translate(
      this.state.websiteLanguage,
      "REQUEST_FORM_INFO_EMBROIDERED"
    );
    if (typeSelected === "embroidered") {
      infoBoxText = translate(
        this.state.websiteLanguage,
        "REQUEST_FORM_INFO_EMBROIDERED"
      );
    } else if (typeSelected === "woven") {
      infoBoxText = translate(
        this.state.websiteLanguage,
        "REQUEST_FORM_INFO_WOVEN"
      );
    } else if (typeSelected === "lasercut") {
      infoBoxText = translate(
        this.state.websiteLanguage,
        "REQUEST_FORM_INFO_LASERCUT"
      );
    } else if (typeSelected === "pvc") {
      infoBoxText = translate(
        this.state.websiteLanguage,
        "REQUEST_FORM_INFO_PVC"
      );
    } else if (typeSelected === "printed") {
      infoBoxText = translate(
        this.state.websiteLanguage,
        "REQUEST_FORM_INFO_PRINTED"
      );
    }
    return <div className="info-box">{infoBoxText}</div>;
  };

  render() {
    return (
      <ParallaxProvider>
        <div className="root-container custom-patches">
          <Navbar
            showBackButton={true}
            websiteLanguage={this.state.websiteLanguage}
            transparent={true}
            backButtonTextRef={"HEADER_BACK"}
          />
          <section className="section-custom-request">
            <div className="section-container custom-request">
              {this.state.requestSubmitted ? (
                <div className="request-form-confirmation">
                  {translate(
                    this.state.websiteLanguage,
                    "REQUEST_FORM_CONFIRMATION_MESSAGE"
                  )}
                  <NavLink to="/" className="navbar-backlink">
                    <div className="navbar-back-button-text">
                      {translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_BACK_LINK"
                      )}
                    </div>
                  </NavLink>
                </div>
              ) : (
                <div className="form-content">
                  <div className="custom-request-header">
                    {translate(
                      this.state.websiteLanguage,
                      "REQUEST_FORM_TITLE"
                    )}
                  </div>
                  <div className="custom-request-form-intro-text">
                    {translate(
                      this.state.websiteLanguage,
                      "REQUEST_FORM_INTRO"
                    )}
                  </div>

                  {/* Product selector */}

                  <div className="form-label-big product">
                    {translate(
                      this.state.websiteLanguage,
                      "REQUEST_FORM_PRODUCT"
                    )}
                  </div>

                  <div className="custom-request-selectors">
                    <div
                      className={`pay-method-btn patch ${
                        this.state.product === "patch" ? "enabled" : "disabled"
                      }`}
                      onClick={() => this.setState({ product: "patch" })}
                    >
                      {translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_TYPE_PATCH"
                      )}
                    </div>
                    <div
                      className={`pay-method-btn coin ${
                        this.state.product === "coin" ? "enabled" : "disabled"
                      }`}
                      onClick={() => this.setState({ product: "coin" })}
                    >
                      {translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_TYPE_COIN"
                      )}
                    </div>
                  </div>

                  <form
                    onSubmit={this.handleSubmitRequest}
                    className="custom-request-form"
                  >
                    {/* Name */}
                    <div className="form-label-big">
                      {translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_NAME"
                      )}
                    </div>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_NAME"
                      )}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.name}
                    />

                    {/* Email */}
                    <div className="form-label-big">
                      {translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_EMAIL"
                      )}
                    </div>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_EMAIL"
                      )}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.email}
                    />
                    <div className="form-label-big">
                      {translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_DESCRIPTION"
                      )}
                    </div>

                    {/* Description */}
                    <textarea
                      id="description"
                      type="text"
                      name="description"
                      placeholder={translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_DESCRIPTION_PLACEHOLDER"
                      )}
                      rows={3}
                      onFocus={this.handleFocus}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.description}
                    />

                    {/* Type of patch*/}
                    {this.state.product === "patch" && (
                      <React.Fragment>
                        <div className="form-label-big">
                          {translate(
                            this.state.websiteLanguage,
                            "REQUEST_FORM_TYPE"
                          )}
                        </div>
                        <div className="custom-request-selectors">
                          <div
                            className={`pay-method-btn embroidered ${
                              this.state.type === "embroidered"
                                ? "enabled"
                                : "disabled"
                            }`}
                            onClick={() =>
                              this.setState({ type: "embroidered" })
                            }
                          >
                            {translate(
                              this.state.websiteLanguage,
                              "REQUEST_FORM_TYPE_EMBROIDERED"
                            )}
                          </div>
                          <div
                            className={`pay-method-btn woven ${
                              this.state.type === "woven"
                                ? "enabled"
                                : "disabled"
                            }`}
                            onClick={() => this.setState({ type: "woven" })}
                          >
                            {translate(
                              this.state.websiteLanguage,
                              "REQUEST_FORM_TYPE_WOVEN"
                            )}
                          </div>
                          <div
                            className={`pay-method-btn lasercut ${
                              this.state.type === "lasercut"
                                ? "enabled"
                                : "disabled"
                            }`}
                            onClick={() => this.setState({ type: "lasercut" })}
                          >
                            {translate(
                              this.state.websiteLanguage,
                              "REQUEST_FORM_TYPE_LASERCUT"
                            )}
                          </div>
                          <div
                            className={`pay-method-btn pvc ${
                              this.state.type === "pvc" ? "enabled" : "disabled"
                            }`}
                            onClick={() => this.setState({ type: "pvc" })}
                          >
                            {translate(
                              this.state.websiteLanguage,
                              "REQUEST_FORM_TYPE_PVC"
                            )}
                          </div>
                          <div
                            className={`pay-method-btn printed ${
                              this.state.type === "printed"
                                ? "enabled"
                                : "disabled"
                            }`}
                            onClick={() => this.setState({ type: "printed" })}
                          >
                            {translate(
                              this.state.websiteLanguage,
                              "REQUEST_FORM_TYPE_PRINTED"
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    {this.state.type !== undefined &&
                      this.state.product !== "coin" &&
                      this.renderInfoBox(this.state.type)}

                    {/* Amount */}
                    <div className="form-label-big">
                      {translate(
                        this.state.websiteLanguage,
                        "REQUEST_FORM_TYPE_QUANTITY"
                      )}
                    </div>
                    <div className="custom-request-selectors">
                      {(this.state.type === "lasercut" ||
                        this.state.type === "printed") && (
                        <div
                          className={`pay-method-btn low ${
                            this.state.amount === "0-50"
                              ? "enabled"
                              : "disabled"
                          }`}
                          onClick={() => this.setState({ amount: "0-50" })}
                        >
                          {translate(
                            this.state.websiteLanguage,
                            "REQUEST_FORM_AMOUNT_UNDER_50"
                          )}
                        </div>
                      )}
                      <div
                        className={`pay-method-btn medium ${
                          this.state.amount === "50-100"
                            ? "enabled"
                            : "disabled"
                        }`}
                        onClick={() => this.setState({ amount: "50-100" })}
                      >
                        {translate(
                          this.state.websiteLanguage,
                          "REQUEST_FORM_AMOUNT_50_100"
                        )}
                      </div>
                      <div
                        className={`pay-method-btn high ${
                          this.state.amount === "100+" ? "enabled" : "disabled"
                        }`}
                        onClick={() => this.setState({ amount: "100+" })}
                      >
                        {translate(
                          this.state.websiteLanguage,
                          "REQUEST_FORM_AMOUNT_OVER_100"
                        )}
                      </div>
                      <div
                        className={`pay-method-btn very-high ${
                          this.state.amount === "200+" ? "enabled" : "disabled"
                        }`}
                        onClick={() => this.setState({ amount: "200+" })}
                      >
                        {translate(
                          this.state.websiteLanguage,
                          "REQUEST_FORM_AMOUNT_OVER_200"
                        )}
                      </div>
                    </div>

                    {/* Send request button */}
                    <button
                      type="submit"
                      className={`custom-request-send-button${
                        this.state.sendingRequest ? " submitting" : ""
                      }`}
                    >
                      {this.state.sendingRequest ? (
                        <StageSpinner size="40" loading={true} color="#000" />
                      ) : (
                        translate(
                          this.state.websiteLanguage,
                          "REQUEST_FORM_SEND"
                        )
                      )}
                    </button>

                    {/* Error message */}
                    <div
                      className={`checkout-form-validation-message ${
                        this.state.errorMessage ? "visible" : "hidden"
                      }`}
                    >
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </section>
        </div>
      </ParallaxProvider>
    );
  }
}

export default observer(CustomPatches);
