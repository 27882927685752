import React from "react";
import { ElementsConsumer, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { withRouter } from "react-router-dom";

class _WalletTest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paymentRequest: null
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (!prevProps.stripe && this.props.stripe) {

            const { stripe } = this.props;

            if (stripe) {
                console.log("running pr");
                const pr = await stripe.paymentRequest({
                  country: 'NO',
                  currency: 'nok',
                  total: {
                    label: 'Demo total',
                    amount: 300,
                  },
                  requestPayerName: true,
                  requestPayerEmail: true,
                });
                console.log("pr:", pr);
                // Check the availability of the Payment Request API.
                pr.canMakePayment().then(result => {
                    console.log("result:", result);
                    if (result) {
                        this.setState({ paymentRequest: pr });
                    }
                });
              }
          }
    }

    render() {
        const {
            paymentRequest
          } = this.state;
        return(
            paymentRequest ? <PaymentRequestButtonElement options={{paymentRequest}} /> : <div>funket ikke</div>
        )
    }
}

const WalletTest = withRouter(_WalletTest);

export default function InjectedWalletTest() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <WalletTest stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}
