export const norwegian = [
  { id: "REQUEST_FORM_TITLE", translation: "Custom patcher og coins" },
  { id: "REQUEST_FORM_NAME", translation: "Navn" },
  { id: "REQUEST_FORM_EMAIL", translation: "E-post" },
  { id: "REQUEST_FORM_DESCRIPTION", translation: "Beskrivelse" },
  {
    id: "REQUEST_FORM_DESCRIPTION_PLACEHOLDER",
    translation: "Design, størrelse, farger, materialer, etc.",
  },
  { id: "REQUEST_FORM_PRODUCT", translation: "Produkt" },
  { id: "REQUEST_FORM_TYPE", translation: "Type patch" },
  { id: "REQUEST_FORM_TYPE_COIN", translation: "Coin" },
  { id: "REQUEST_FORM_TYPE_PATCH", translation: "Patch" },
  { id: "REQUEST_FORM_TYPE_EMBROIDERED", translation: "Brodert" },
  { id: "REQUEST_FORM_TYPE_WOVEN", translation: "Vevd" },
  { id: "REQUEST_FORM_TYPE_LASERCUT", translation: "Laserkuttet" },
  { id: "REQUEST_FORM_TYPE_PVC", translation: "PVC" },
  { id: "REQUEST_FORM_TYPE_PRINTED", translation: "Trykt" },
  { id: "REQUEST_FORM_TYPE_QUANTITY", translation: "Kvantitet" },
  { id: "REQUEST_FORM_AMOUNT_UNDER_50", translation: "10-50" },
  { id: "REQUEST_FORM_AMOUNT_50_100", translation: "50-100" },
  { id: "REQUEST_FORM_AMOUNT_OVER_100", translation: "100-200" },
  { id: "REQUEST_FORM_AMOUNT_OVER_200", translation: "Over 200" },
  { id: "REQUEST_FORM_SEND", translation: "Send" },
  {
    id: "REQUEST_FORM_EMAIL_ERROR",
    translation: "Det ser ikke ut som du har fylt ut en gyldig e-postadresse",
  },
  {
    id: "REQUEST_FORM_INFO_EMBROIDERED",
    translation:
      "Minimum antall er 50 stk. per design. Broderte patcher lages ved at motivet blir brodert på egnet stoff med tråd i ulike farger. Kan være delvis brodert eller helbrodert. Ved delvis brodering vil motivet være hevet noe over stoffet under. Lages i ulike størrelser og fasonger. Noe begrenset når det kommer til komplekse design med høyt detaljnivå eller små detaljer.",
  },
  {
    id: "REQUEST_FORM_INFO_WOVEN",
    translation:
      "Minimum antall er 50 stk. per design. Vevde patcher lages ved å veve et stykke stoff fra bunnen av, der motivet blir en integrert del av stoffet. Dette gir en glatt overflate og muliggjør et høyt detaljnivå. Kan lages i ulike farger og størrelser, men bør helst være i rektangulær eller sirkulær form.",
  },
  {
    id: "REQUEST_FORM_INFO_LASERCUT",
    translation:
      "Minimum antall er 10 stk. per design. Laserkuttede patcher lages ved å skjære ut motivet i et stykke nylonstoff ved hjelp av laser. Dette muliggjør et høyt detaljnivå, men krever at det ferdig kuttede stoffet er en sammenhengende bit. Dette gir noen begrensninger med tanke på utformingen av motivet. Det laserkuttede stoffstykket legges oppå et annet materiale for å skape kontrast. Det underliggende materialet fåes i ulike typer refleksivt materiale eller ikke-refleksivt stoff. Fåes i ulike fasonger, men det er noen begrensninger i valg av farge på stoff. Vanlige farger er olivengrønn, svart, coyote brun og multicam.",
  },
  {
    id: "REQUEST_FORM_INFO_PVC",
    translation:
      "Minimum antall er 50 stk. per design. PVC-patcher lages ved å støpe et motiv i myk gummi. Overganger mellom ulike detaljer i designet eller ulike farger skaper riller i overflaten. Ulike teksturer og mønstre er mulig. Kan lages i ulike farger og fasonger, og kan lages med detaljer som lyser i mørket.",
  },
  {
    id: "REQUEST_FORM_INFO_PRINTED",
    translation:
      "Minimum antall er 10 stk. per design. Dette er IR-patcher som holder milspec standard, det vil si at de tilfredsstiller høye krav til refleksjonsevne og refleksjonsvinkel. Reflekterer ikke hvitt lys og har en glatt overflate. Motivet trykkes på refleksivt materiale, der den svarte fargen utgjør den refleksive delen av motivet, dersom patchen belyses med IR-diode fra f.eks. nattoptikk. Stor variasjon i farger og motiv er mulig, i tillegg til et høyt detaljnivå. Disse patchene er relativt dyre grunnet de refleksive egenskapene. Minimum antall er 10 stk. per design.",
  },
  {
    id: "REQUEST_FORM_FIELDS_ERROR",
    translation: "Feltene er ikke utfylt i henhold",
  },
  {
    id: "REQUEST_FORM_CONFIRMATION_MESSAGE",
    translation:
      "Takk for forespørselen. Vi tar kontakt med deg så fort som mulig.",
  },
  { id: "REQUEST_FORM_BACK_LINK", translation: "Til forsiden" },
  {
    id: "REQUEST_FORM_INTRO",
    translation:
      "Takk for interessen. Oppgi kontaktinfo og beskrivelse av ønsket produkt under. Seriøse henvendelser vil bli besvart per e-post.",
  },
];

export const english = [
  { id: "REQUEST_FORM_TITLE", translation: "Custom Patches" },
  { id: "REQUEST_FORM_NAME", translation: "Name" },
  { id: "REQUEST_FORM_EMAIL", translation: "Email" },
  { id: "REQUEST_FORM_DESCRIPTION", translation: "Descritpion" },
  {
    id: "REQUEST_FORM_DESCRIPTION_PLACEHOLDER",
    translation: "Design, size, colors, materials, etc.",
  },
  { id: "REQUEST_FORM_PRODCT", translation: "Produkt" },
  { id: "REQUEST_FORM_TYPE", translation: "Type of patch" },
  { id: "REQUEST_FORM_TYPE_COIN", translation: "Coin" },
  { id: "REQUEST_FORM_TYPE_PATCH", translation: "Patch" },
  { id: "REQUEST_FORM_TYPE_EMBROIDERED", translation: "Embroidered" },
  { id: "REQUEST_FORM_TYPE_WOVEN", translation: "Woven" },
  { id: "REQUEST_FORM_TYPE_LASERCUT", translation: "Laser cut" },
  { id: "REQUEST_FORM_TYPE_PVC", translation: "PVC" },
  { id: "REQUEST_FORM_TYPE_PRINTED", translation: "Printed" },
  { id: "REQUEST_FORM_TYPE_QUANTITY", translation: "Quantity" },
  { id: "REQUEST_FORM_AMOUNT_UNDER_50", translation: "10-50" },
  { id: "REQUEST_FORM_AMOUNT_50_100", translation: "50-100" },
  { id: "REQUEST_FORM_AMOUNT_OVER_100", translation: "100-200" },
  { id: "REQUEST_FORM_AMOUNT_OVER_200", translation: "Over 200" },
  { id: "REQUEST_FORM_SEND", translation: "Send" },
  {
    id: "REQUEST_FORM_EMAIL_ERROR",
    translation: "Your email does not appear to be valid",
  },
  {
    id: "REQUEST_FORM_INFO_EMBROIDERED",
    translation:
      "Minimum quantity is 50 pcs. with the same design. Embroidered patches are made by embroidering the design on suitable fabric with thread in different colours. Can be partially embroidered or fully embroidered. Comes in various sizes and shapes.",
  },
  {
    id: "REQUEST_FORM_INFO_WOVEN",
    translation:
      "Minimum quantity is 50 pcs. with the same design. Woven patches are made by weaving a piece of fabric from scratch, where the design becomes an integral part of the fabric. This gives a smooth surface and enables a high level of detail. Can be made in different colors and sizes, but should preferably be in a rectangular or circular shape.",
  },
  {
    id: "REQUEST_FORM_INFO_LASERCUT",
    translation:
      "Minimum quantity is 10 pcs. with the same design. Laser cut patches are made by cutting the design on a piece of nylon fabric using laser. This enables a high level of detail, but requires that the cut fabric is one continuous piece. This may require small alterations to the design. The laser cut piece of fabric is placed on top of another material to create contrast. The underlying material is available in various types of reflective material or non-reflective material. Available in various shapes, but there are some limitations in the choice of fabric colour. Common colors are olive green, black, coyote brown and multicam.",
  },
  {
    id: "REQUEST_FORM_INFO_PVC",
    translation:
      "Minimum quantity is 50 pcs. with the same design. PVC patches are made by molding a design in soft rubber. Transitions between different details in the design or different colors create grooves in the surface. Different textures and patterns are possible. Available in various colors and shapes, and can be made with details that glow in the dark.",
  },
  {
    id: "REQUEST_FORM_INFO_PRINTED",
    translation:
      "Minimum quantity is 10 pcs. with the same design. These are IR patches that meet the milspec standard, i.e. they satisfy high requirements for reflectivity and angle of reflection. Does not reflect white light and has a smooth surface. The design is printed on reflective material, where the black colors form the reflective part of the design, when the patch is illuminated with an IR diode from e.g. night optic devices. Great variaty in terms of colors and high level of detail. These patches are relatively expensive due to their reflexive properties.",
  },
  {
    id: "REQUEST_FORM_FIELDS_ERROR",
    translation: "Please fill out all the fields",
  },
  {
    id: "REQUEST_FORM_CONFIRMATION_MESSAGE",
    translation:
      "Thanks for the request. We´ll get in touch as soon as possible.",
  },
  { id: "REQUEST_FORM_BACK_LINK", translation: "Go to front page" },
  {
    id: "REQUEST_FORM_INTRO",
    translation:
      "Thanks for the interest. Please describe the patch/coin and submit the form. We will reply by email to your request.",
  },
];

export const swedish = [
  { id: "REQUEST_FORM_TITLE", translation: "Custom patchar" },
  { id: "REQUEST_FORM_NAME", translation: "Namn" },
  { id: "REQUEST_FORM_EMAIL", translation: "E-post" },
  { id: "REQUEST_FORM_DESCRIPTION", translation: "Beskrivning" },
  { id: "REQUEST_FORM_PRODUCT", translation: "Produkt" },
  { id: "REQUEST_FORM_TYPE", translation: "Typ patch" },
  { id: "REQUEST_FORM_TYPE_COIN", translation: "Coin" },
  { id: "REQUEST_FORM_TYPE_PATCH", translation: "Patch" },
  { id: "REQUEST_FORM_TYPE_EMBROIDERED", translation: "Broderat" },
  { id: "REQUEST_FORM_TYPE_WOVEN", translation: "Vävd" },
  {
    id: "REQUEST_FORM_DESCRIPTION_PLACEHOLDER",
    translation: "Design, storlek, färger, material mm.",
  },
  { id: "REQUEST_FORM_TYPE_LASERCUT", translation: "Laserskuren" },
  { id: "REQUEST_FORM_TYPE_PVC", translation: "PVC" },
  { id: "REQUEST_FORM_TYPE_PRINTED", translation: "Tryckt" },
  { id: "REQUEST_FORM_TYPE_QUANTITY", translation: "Kvantitet" },
  { id: "REQUEST_FORM_AMOUNT_UNDER_50", translation: "10-50" },
  { id: "REQUEST_FORM_AMOUNT_50_100", translation: "50-100" },
  { id: "REQUEST_FORM_AMOUNT_OVER_100", translation: "100-200" },
  { id: "REQUEST_FORM_AMOUNT_OVER_200", translation: "Över 200" },
  { id: "REQUEST_FORM_SEND", translation: "Send" },
  {
    id: "REQUEST_FORM_EMAIL_ERROR",
    translation: "Det ser inte ut som om du har fyllt i en giltig e-postadress",
  },
  {
    id: "REQUEST_FORM_INFO_EMBROIDERED",
    translation:
      "Minsta antal är 50 st. per design. Broderte patchar lages genom att motivet blir brodert på egnet stoff med tråd i olika färger. Kan vara delvis brodert eller helbrodert. Lages i olika storlekar och fasonger.",
  },
  {
    id: "REQUEST_FORM_INFO_WOVEN",
    translation:
      "Minsta kvantitet är 50 st. per design. Vävda patchar görs genom att väva en bit tyg från grunden, där designen blir en integrerad del av tyget. Detta ger en slät yta och möjliggör en hög detaljnivå. Kan göras i olika färger och storlekar men ska helst vara i rektangulär eller cirkulär form.",
  },
  {
    id: "REQUEST_FORM_INFO_LASERCUT",
    translation:
      "Minsta antal är 10 st. per design. Laserskurne patchar lages vid å skjære ut motivet i et stycke nylonstoff vid hjelp av laser. Detta möjligt gör ett högt detaljnivå, men kräver att det färdiga kuttede stoffet är en relaterad bit. Detta ger några begränsningar med tanke på utformningen av motivet. Det laserkuttede stoffstykket legges oppå et annat material för att skapa kontrast. Det underliggande materialet finns i olika typer av refleksivt material eller icke-refleksivt material. Fåes i olika fasonger, men det är några begränsningar i val av färg på stoff. Vanliga färger är olivgrön, svart, coyote brun och multicam.",
  },
  {
    id: "REQUEST_FORM_INFO_PVC",
    translation:
      "Minsta kvantitet är 50 stycken. per design. PVC-patchar tillverkas genom att gjuta ett motiv i mjukt gummi. Övergångar mellan olika detaljer i designen eller olika färger skapar spår i ytan. Olika texturer och mönster är möjliga. Finns i olika färger och former, och kan göras med detaljer som lyser i mörkret.",
  },
  {
    id: "REQUEST_FORM_INFO_PRINTED",
    translation:
      "Minsta antal är 10 st. per design. Detta är IR-patchar som håller milspec standard, det vill si att de tillfredsställer höga krav till reflektionsevne och reflektionsvinkel. Reflekterar inte vitt lys och har en glatt yta. Motivet trycks på refleksivt material, där den svarta färgen utgör den reflexiva delen av motivet, om patchen belyses med IR-diod från f.eks. nattoptikk. Stort variation i färger er möjligt och et högt detaljnivå. Dessa patcharna är relativt dyra grundet de refleksiva egenskaperna.",
  },
  {
    id: "REQUEST_FORM_FIELDS_ERROR",
    translation: "Du måste fylla i alla fält",
  },
  {
    id: "REQUEST_FORM_CONFIRMATION_MESSAGE",
    translation: "Tack för förfrågan. Vi hör av oss så snart som möjligt.",
  },
  { id: "REQUEST_FORM_BACK_LINK", translation: "Till framsidan" },
  {
    id: "REQUEST_FORM_INTRO",
    translation:
      "Tack för intresset. Beskriv patch/coin och skicka in formuläret. Seriösa förfrågningar kommer att besvaras via e-post.",
  },
];

export const danish = [
  { id: "REQUEST_FORM_TITLE", translation: "Custom patches" },
  { id: "REQUEST_FORM_NAME", translation: "Navn" },
  { id: "REQUEST_FORM_EMAIL", translation: "E-mail" },
  { id: "REQUEST_FORM_DESCRIPTION", translation: "Beskrivelse" },
  {
    id: "REQUEST_FORM_DESCRIPTION_PLACEHOLDER",
    translation: "Design, størrelse, farver, materialer mv.",
  },
  { id: "REQUEST_FORM_PRODCT", translation: "Produkt" },
  { id: "REQUEST_FORM_PRODUCT", translation: "Produkt" },
  { id: "REQUEST_FORM_TYPE", translation: "Type patch" },
  { id: "REQUEST_FORM_TYPE_COIN", translation: "Coin" },
  { id: "REQUEST_FORM_TYPE_PATCH", translation: "Patch" },
  { id: "REQUEST_FORM_TYPE_EMBROIDERED", translation: "Broderet" },
  { id: "REQUEST_FORM_TYPE_WOVEN", translation: "Vævet" },
  { id: "REQUEST_FORM_TYPE_LASERCUT", translation: "Laserskåret" },
  { id: "REQUEST_FORM_TYPE_PVC", translation: "PVC" },
  { id: "REQUEST_FORM_TYPE_PRINTED", translation: "Trykt" },
  { id: "REQUEST_FORM_TYPE_QUANTITY", translation: "Antal" },
  { id: "REQUEST_FORM_AMOUNT_UNDER_50", translation: "10-50" },
  { id: "REQUEST_FORM_AMOUNT_50_100", translation: "50-100" },
  { id: "REQUEST_FORM_AMOUNT_OVER_100", translation: "100-200" },
  { id: "REQUEST_FORM_AMOUNT_OVER_200", translation: "Over 200" },
  { id: "REQUEST_FORM_SEND", translation: "Send" },
  {
    id: "REQUEST_FORM_EMAIL_ERROR",
    translation: "Din e-mail ser ikke ud til at være gyldig",
  },
  {
    id: "REQUEST_FORM_INFO_EMBROIDERED",
    translation:
      "Minimum antal er 50 stk. med samme motiv. Broderte patcher lages ved at motivet bliver brodert på egnet stoff med tråd i forskellige farver. Kan være delvis brodert eller helbrodert. Lages i forskellige størrelser og fasonger.",
  },
  {
    id: "REQUEST_FORM_INFO_WOVEN",
    translation:
      "Minimum antal er 50 stk. pr design. Vævede patches laves ved at væve et stykke stof fra bunden, hvor motivet bliver en integreret del af stoffet. Dette giver en glat overflade og muliggør et højt detaljeniveau. Kan laves i forskellige farver og størrelser, men skal helst være rektangulær eller cirkulær i formen.",
  },
  {
    id: "REQUEST_FORM_INFO_LASERCUT",
    translation:
      "Minimum antal er 10 stk. pr design. Laserkuttede patches lages ved at skjære ud motivet i et stykke nylonstoff ved hjælp af laser. Dette muliggjør et højt detaljeniveau, men kræver at det færdige kuttede stof er en sammenhængende bit. Dette giver nogen begrensninger med tanke på udformningen af motivet. Det laserkuttede stoffstykket legges oppå et andet materiale for at skabe kontrast. Det underliggende materiale fåes i forskellige typer refleksivt materiale eller ikke-refleksivt stof. Fåes i forskellige fasonger, men det er nogen grænser i valg af farve på stof. Vanlige farver er olivengrønn, sort, coyote brun og multicam.",
  },
  {
    id: "REQUEST_FORM_INFO_PVC",
    translation:
      "Minimum antal 50 stk. pr design. PVC-patches fremstilles ved at støbe et motiv i blødt gummi. Overgange mellem forskellige detaljer i designet eller forskellige farver skaber riller i overfladen. Forskellige teksturer og mønstre er mulige. Fås i forskellige farver og former, og kan laves med detaljer, der lyser i mørke.",
  },
  {
    id: "REQUEST_FORM_INFO_PRINTED",
    translation:
      "Minimum antal er 10 stk. pr design. Dette er IR-patches som holder milspec standard, det vil si at de tilfredsstiller høje krav til refleksjonsevne og refleksionsvinkel. Reflekterer ikke hvidt lys og har en glat overflade. Motivet trykkes på refleksivt materiale, der den sorte farve udgør den refleksive del af motivet, hvis patchen belyses med IR-diode fra f.eks. natoptik. Stor variation i farver og design er mulig, og et højt detaljeniveau. Disse patches er relativt dyre grundet de refleksive egenskaber.",
  },
  {
    id: "REQUEST_FORM_FIELDS_ERROR",
    translation: "Nødvendige felter mangler",
  },
  {
    id: "REQUEST_FORM_CONFIRMATION_MESSAGE",
    translation: "Tak for anmodningen. Vi kontakter dig hurtigst muligt.",
  },
  { id: "REQUEST_FORM_BACK_LINK", translation: "Gå til startsiden" },
  {
    id: "REQUEST_FORM_INTRO",
    translation:
      "Tak for interessen. Beskriv venligst patch/coin og indsend formularen. Seriøse henvendelser vil blive besvaret via e-mail.",
  },
];
