import {
  FREE_SHIPPING_NORWAY,
  FREE_SHIPPING_OTHER,
  SHIPPING_COST_DENMARK_SWEDEN,
  SHIPPING_COST_MAILBOX,
  SHIPPING_COST_STORE,
  SHIPPING_COST_CUSTOM_STORE,
  SHIPPING_COST_LONG_DISTANCE,
  SHIPPING_COST_LONG_DISTANCE_UPS,
  OPRA_DISCOUNT_PERCENTAGE,
  BLACK_FRIDAY_DISCOUNT,
} from "./translations";
import {
  norwegian as defaultNorwegian,
  english as defaultEnglish,
  swedish as defaultSwedish,
  danish as defaultDanish,
} from "./translations";
import {
  norwegian as customRequestNorwegian,
  english as customRquestEnglish,
  swedish as customRequestSwedish,
  danish as customRequestDanish,
} from "./customRequestTranslations";
import { itemsInCart } from "./cartFunctions";
import {
  restrictedProductIds,
  cartContainsHeavyItems,
  cartContainsLargeItems,
  cartContainsMultipleHeavyItems,
  cartContainsMultipleLargeItems,
  cartContainsManyItems,
} from "./helperFunctions";
import { isLongDistanceShipping } from "./shipping";

export const OPRA_DISCOUNT_CODE = "OPRA";
const CART_KEY = "cart";
const TIMESTAMP_CART_KEY = "cartUpdated";
const LANGUAGE_KEY = "language";
const USERDATA_KEY = "user";
const LANGUAGE_BAR_KEY = "hiddenLanguageBar";

/* Language */
const norwegian = defaultNorwegian.concat(customRequestNorwegian);

const english = defaultEnglish.concat(customRquestEnglish);

const swedish = defaultSwedish.concat(customRequestSwedish);

const danish = defaultDanish.concat(customRequestDanish);

/* Strapi */
export const getStrapiUrl = () => {
  const isProduction = process.env.NODE_ENV === "production";
  if (isProduction) {
    return "https://sjanten-server.herokuapp.com";
  } else {
    return "http://localhost:1337";
  }
};

export const capitalizeName = (name) => {
  return name.replace(
    /(?:^|\s|['`''.-])[^-\x60^\x7B-\xDF](?!(\s|$))/g,
    function (a) {
      return a.toUpperCase();
    }
  );
};

export const jegerbueId = "63c07bca452cdb00167327d3";

export const calculateDiscountQuantity = (items) => {
  const numberOfItems = itemsInCart(items);
  let discount = 0;
  if (numberOfItems === 5) {
    discount = 150;
  } else if (numberOfItems === 4) {
    discount = 100;
  } else if (numberOfItems === 3) {
    discount = 60;
  } else if (numberOfItems === 2) {
    discount = 30;
  }
  return discount;
};

export const calculateDiscountCode = (
  items,
  discountType,
  selectedCurrency,
  currencyRates
) => {
  let bottleCount = 0;
  let capsCount = 0;
  for (let i = 0; i < items.length; i++) {
    if (
      discountType === "BLACKFRIDAY" &&
      items[i]._id === "65429ce2a6340f0014698341"
    ) {
      bottleCount = bottleCount + items[i].quantity;
    } else if (
      discountType === "BLACKFRIDAY" &&
      items[i].name.includes("Cap")
    ) {
      capsCount = capsCount + items[i].quantity;
    } else if (
      discountType === "BLACKFRIDAY" &&
      items[i]._id === "66ae875aa341210015813903"
    ) {
      capsCount = capsCount + items[i].quantity;
    }
  }
  const discountPerBottle = BLACK_FRIDAY_DISCOUNT;
  const discountPerCaps = BLACK_FRIDAY_DISCOUNT;
  let totalDiscount =
    discountPerBottle * bottleCount + discountPerCaps * capsCount;
  if (selectedCurrency !== "NOK" && currencyRates) {
    const currencyMultiplier = currencyRates[selectedCurrency].value;
    totalDiscount *= currencyMultiplier;
  } else if (discountType === OPRA_DISCOUNT_CODE) {
    totalDiscount =
      calculateAmount(items, false, false, selectedCurrency, currencyRates) *
      OPRA_DISCOUNT_PERCENTAGE;
  }
  return Math.ceil(totalDiscount);
};

export const calculateAmount = (
  items,
  discount,
  discountType,
  selectedCurrency,
  currencyRates
) => {
  let priceBeforeDiscount = items
    .reduce(
      (acc, item) =>
        acc +
        itemSumAmount(
          item.quantity,
          item.price,
          selectedCurrency,
          currencyRates
        ),
      0
    )
    .toFixed(0);
  priceBeforeDiscount = Number(priceBeforeDiscount);
  if (discount) {
    return Number(
      priceBeforeDiscount -
        calculateDiscountCode(
          items,
          discountType,
          selectedCurrency,
          currencyRates
        )
    );
  }
  return Number(priceBeforeDiscount);
};

export const productsAmount = (
  items,
  discount,
  discountType,
  selectedCurrency,
  currencyRates
) => {
  let totalAmount = calculateAmount(
    items,
    discount,
    discountType,
    selectedCurrency,
    currencyRates
  );
  return totalAmount;
};

export const itemSumAmount = (
  quantity,
  price,
  selectedCurrency,
  currencyRates
) => {
  let sumAmount = quantity * price;
  if (selectedCurrency !== "NOK" && currencyRates) {
    const currencyMultiplier = currencyRates[selectedCurrency].value;
    sumAmount *= currencyMultiplier;
  }
  return Math.ceil(sumAmount);
};

export const calculateShipping = (
  items,
  country,
  shippingMethod,
  selectedCurrency,
  currencyRates,
  shippingPriceFromApi,
  discount,
  discountType
) => {
  let shippingCost = 0;
  const customOrderIndex = items.findIndex(
    (item) => item._id === "customOrder"
  );

  if (customOrderIndex !== -1) {
    if (
      shippingMethod === "mailbox" ||
      shippingMethod === "longDistanceUps" ||
      shippingMethod === "longDistanceStandardMail"
    ) {
      return shippingCost;
    } else if (shippingMethod === "store") {
      shippingCost = SHIPPING_COST_CUSTOM_STORE;
      return shippingCost;
    }
  } else if (
    country === "Sverige" ||
    country === "Danmark" ||
    country === "Germany" ||
    country === "United Kingdom" ||
    country === "Finland" ||
    country === "Netherlands" ||
    country === "Belgium" ||
    country === "France" ||
    country === "Portugal" ||
    country === "Italy" ||
    country === "Ukraine" ||
    country === "Poland" ||
    country === "Latvia" ||
    country === "Lithuania" ||
    country === "Estonia" ||
    country === "Slovakia" ||
    country === "Ireland" ||
    country === "Czech"
  ) {
    if (
      calculateAmount(items, false, false, selectedCurrency, currencyRates) >=
        FREE_SHIPPING_OTHER ||
      calculateAmount(items, false, false, selectedCurrency, currencyRates) ===
        3
    ) {
      return shippingCost;
    } else if (cartContainsHeavyItems(items) || cartContainsManyItems(items)) {
      shippingCost = SHIPPING_COST_DENMARK_SWEDEN + 65;
      if (cartContainsMultipleHeavyItems(items)) {
        shippingCost += 50;
      }
      return shippingCost;
    } else {
      shippingCost = SHIPPING_COST_DENMARK_SWEDEN;
      return shippingCost;
    }
  } else if (isLongDistanceShipping(country)) {
    if (
      calculateAmount(items, false, false, selectedCurrency, currencyRates) ===
      3
    ) {
      return shippingCost;
    } else if (shippingMethod === "longDistanceUps") {
      shippingCost = SHIPPING_COST_LONG_DISTANCE_UPS;
      return shippingCost;
    } else if (cartContainsHeavyItems(items) || cartContainsLargeItems(items)) {
      shippingCost = SHIPPING_COST_LONG_DISTANCE + 75;
      if (
        cartContainsMultipleHeavyItems(items) ||
        cartContainsMultipleLargeItems(items)
      ) {
        shippingCost += 55;
      }
      return shippingCost;
    } else {
      shippingCost = SHIPPING_COST_LONG_DISTANCE;
      return shippingCost;
    }
  } else {
    let isFreeShipping = false;
    if (discount) {
      isFreeShipping =
        calculateAmount(
          items,
          discount,
          discountType,
          selectedCurrency,
          currencyRates
        ) >= FREE_SHIPPING_NORWAY && shippingMethod === "mailbox";
    } else {
      isFreeShipping =
        calculateAmount(items, false, false, selectedCurrency, currencyRates) >=
          FREE_SHIPPING_NORWAY && shippingMethod === "mailbox";
    }
    const isDummyProduct =
      calculateAmount(items, false, false, selectedCurrency, currencyRates) ===
      3;
    if (isFreeShipping || isDummyProduct) {
      return shippingCost;
    } else {
      if (shippingPriceFromApi) {
        shippingCost = shippingPriceFromApi;
        if (shippingMethod === "mailbox") {
          if (cartContainsHeavyItems(items) || cartContainsManyItems(items)) {
            shippingCost += 10;
            if (cartContainsMultipleHeavyItems(items)) {
              shippingCost += 20;
            }
          }
        }
      } else if (shippingMethod === "mailbox") {
        shippingCost = SHIPPING_COST_MAILBOX;
      } else if (shippingMethod === "store") {
        shippingCost = SHIPPING_COST_STORE;
      }
      return shippingCost;
    }
  }
};

export const cartContainsCallsignsPack = (cartItems) => {
  const callsignsInCart = cartItems.findIndex(
    (item) => item._id === "617e61cc5c82f1001837e3e4"
  );
  if (callsignsInCart !== -1) {
    return true;
  }
  return false;
};

export const countryMapper = (country) => {
  switch (country) {
    case "Ukraine":
      return "Ukraina";
    case "Germany":
      return "Tyskland";
    case "Netherlands":
      return "Nederland";
    case "United Kingdom":
      return "Storbritannia";
    case "France":
      return "Frankrike";
    case "Belgium":
      return "Belgia";
    case "Spain":
      return "Spania";
    case "NewZealand":
      return "New Zealand";
    case "Italy":
      return "Italia";
    case "Lithuania":
      return "Litauen";
    case "Estonia":
      return "Estland";
    case "Poland":
      return "Polen";
    case "Czech":
      return "Tsjekkia";
    case "Ireland":
      return "Irland";
    case "Japan":
      return "Japan";
    default:
      return country;
  }
};

export const shippingAmount = (
  items,
  country,
  shippingMethod,
  selectedCurrency,
  currencyRates,
  shippingPriceFromApi,
  discount,
  discountType
) => {
  let shippingCosts = calculateShipping(
    items,
    country,
    shippingMethod,
    selectedCurrency,
    currencyRates,
    shippingPriceFromApi,
    discount,
    discountType
  );
  if (selectedCurrency !== "NOK" && currencyRates) {
    const currencyMultiplier = currencyRates[selectedCurrency].value;
    shippingCosts *= currencyMultiplier;
  }
  return Math.ceil(shippingCosts);
};

export const totalAmount = (
  items,
  country,
  discount,
  discountType,
  shippingMethod,
  selectedCurrency,
  currencyRates,
  shippingPriceFromApi,
  donationAmountSelected
) => {
  let priceBeforeDiscount =
    productsAmount(
      items,
      discount,
      discountType,
      selectedCurrency,
      currencyRates
    ) +
    shippingAmount(
      items,
      country,
      shippingMethod,
      selectedCurrency,
      currencyRates,
      shippingPriceFromApi,
      discount,
      discountType
    );
  if (donationAmountSelected > 0) {
    priceBeforeDiscount += donationAmountSelected;
  }
  return Number(priceBeforeDiscount);
};

export const vatTotalAmount = (
  items,
  country,
  discount,
  discountType,
  shippingMethod,
  selectedCurrency,
  currencyRates,
  shippingPriceFromApi
) => {
  const vatDenominator = 1.25;
  const amount = totalAmount(
    items,
    country,
    discount,
    discountType,
    shippingMethod,
    selectedCurrency,
    currencyRates,
    shippingPriceFromApi
  );
  return Math.round(amount - amount / vatDenominator);
};

export const validEmailAddress = (email) => {
  const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  return emailValid;
};

export const phoneNumberIsValid = (phoneNumber, paymentMethod) => {
  const emptyPhoneNumber = phoneNumber.trim().length === 0;
  if (paymentMethod === "vipps" && !emptyPhoneNumber) {
    const phoneNumberDigitsOnly = phoneNumber
      .replace(/\s+/g, "")
      .replace(/[A-Za-z]+/, "")
      .replace("+47", "")
      .replace(/^(0047)/, "")
      .replace("+", "");
    return phoneNumberDigitsOnly.toString().length === 8;
  }
  return true;
};

export const setUser = (value, cartKey = USERDATA_KEY) => {
  if (localStorage) {
    localStorage.setItem(cartKey, JSON.stringify(value));
  }
};

export const getUser = (cartKey = USERDATA_KEY) => {
  if (localStorage && localStorage.getItem(cartKey)) {
    return JSON.parse(localStorage.getItem(cartKey));
  }
  return undefined;
};

export const clearUser = (cartKey = USERDATA_KEY) => {
  if (localStorage) {
    localStorage.removeItem(cartKey);
  }
};

/* Cart functions */
export const setCart = (value, cartKey = CART_KEY) => {
  if (sessionStorage) {
    const timeStamp = Date.now();
    sessionStorage.setItem(TIMESTAMP_CART_KEY, JSON.stringify(timeStamp));
    sessionStorage.setItem(cartKey, JSON.stringify(value));
  }
};

export const getCart = (cartKey = CART_KEY) => {
  if (sessionStorage && sessionStorage.getItem(cartKey)) {
    if (sessionStorage.getItem(TIMESTAMP_CART_KEY)) {
      const timeStamp = JSON.parse(sessionStorage.getItem(TIMESTAMP_CART_KEY));
      const timeNow = Date.now();
      const timeDiff = timeNow - timeStamp;
      const timeDiffInHours = timeDiff / 3600000;
      if (timeDiffInHours > 4) {
        clearCart();
        return [];
      } else {
        return JSON.parse(sessionStorage.getItem(cartKey));
      }
    } else {
      return JSON.parse(sessionStorage.getItem(cartKey));
    }
  } else {
    return [];
  }
};

export const clearCart = (cartKey = CART_KEY) => {
  if (sessionStorage) {
    sessionStorage.removeItem(cartKey);
  }
};

export const containsRestrictedProducts = (items, country) => {
  if (country === "Norge" || country === "Sverige" || country === "Danmark") {
    return false;
  } else {
    for (let i = 0; i < items.length; i++) {
      if (restrictedProductIds.includes(items[i]._id)) {
        return true;
      }
    }
  }
};

export const isRestrictedCountry = (country) => {
  if (country === "Norge" || country === "Sverige" || country === "Danmark") {
    return false;
  }
  return true;
};

export const isRestrictedProduct = (item, country) => {
  if (isRestrictedCountry(country) && restrictedProductIds.includes(item._id)) {
    return true;
  }
  return false;
};

export const showSizeGuideButton = (item) => {
  if (item.showSizeGuideButton) {
    return true;
  }
  return false;
};

export const removeRestrictedItemsFromCart = (items) => {
  let filteredItems = items;
  for (let i = 0; i < items.length; i++) {
    if (restrictedProductIds.includes(items[i]._id)) {
      filteredItems = filteredItems.filter((item) => item._id !== items[i]._id);
    }
  }
  return filteredItems;
};

// Return form URL
export const returnFormUrl = (language) => {
  if (language === "sv") {
    return "https://sjanten.s3.eu-central-1.amazonaws.com/969e32d149dc424cb01802b516902606.pdf";
  } else if (language === "da") {
    return "https://sjanten.s3.eu-central-1.amazonaws.com/f99486601fb94a0fb30714ffce8bf489.pdf";
  }
  if (language === "en") {
    return "https://sjanten.s3.eu-central-1.amazonaws.com/a7d067f846484deeb01c9bb63f7d85a0.pdf";
  } else {
    return "https://sjanten.s3.eu-central-1.amazonaws.com/8ac9be080d114bbf85aea58432330a49.pdf";
  }
};

// Languages
const isDanishDomain = () => {
  return window.location.href.includes(".dk");
};

const isSwedishDomain = () => {
  return window.location.href.includes(".se");
};

export const getLanguageFromParams = () => {
  let params = new URL(window.location).searchParams;
  return params.get("language");
};

export const getLanguageFromBrowser = () => {
  let languageFromBrowser = navigator.language;

  if (
    languageFromBrowser === "nb" ||
    languageFromBrowser === "nb-no" ||
    languageFromBrowser === "nb-NO" ||
    languageFromBrowser === "nn-NO" ||
    languageFromBrowser === "nn-no" ||
    languageFromBrowser === "nn"
  ) {
    return "no";
  } else if (
    languageFromBrowser === "sv" ||
    languageFromBrowser === "sv-se" ||
    languageFromBrowser === "sv-SE" ||
    isSwedishDomain()
  ) {
    return "sv";
  } else if (
    languageFromBrowser === "da" ||
    languageFromBrowser === "da-dk" ||
    isDanishDomain()
  ) {
    return "da";
  }
  return "en";
};

export const translate = (language, stringId) => {
  let obj;
  if (language === "no") {
    obj = norwegian.find((translation) => translation.id === stringId);
    return obj.translation;
  } else if (language === "sv") {
    obj = swedish.find((translation) => translation.id === stringId);
    return obj.translation;
  } else if (language === "da") {
    obj = danish.find((translation) => translation.id === stringId);
    return obj.translation;
  }
  obj = english.find((translation) => translation.id === stringId);
  return obj.translation;
};

export const setLanguageCookie = (value, languageKey = LANGUAGE_KEY) => {
  if (localStorage) {
    localStorage.setItem(languageKey, JSON.stringify(value));
  }
};

export const getLanguageFromCookie = (languageKey = LANGUAGE_KEY) => {
  if (localStorage && localStorage.getItem(languageKey)) {
    return JSON.parse(localStorage.getItem(languageKey));
  }
  return undefined;
};

export const languageSwitcher = (currentLanguage) => {
  if (
    currentLanguage === "no" ||
    currentLanguage === "sv" ||
    currentLanguage === "da"
  ) {
    return "en";
  } else if (currentLanguage === "en") {
    const browserLanguage = getLanguageFromBrowser();
    switch (browserLanguage) {
      case "da":
        return "da";
      case "sv":
        return "sv";
      default:
        return "no";
    }
  }
};

export const setLanguageBarCookie = (languageBarKey = LANGUAGE_BAR_KEY) => {
  if (localStorage) {
    localStorage.setItem(languageBarKey, JSON.stringify("true"));
  }
};

export const getLanguageBarCookie = (languageBarKey = LANGUAGE_BAR_KEY) => {
  if (localStorage && localStorage.getItem(languageBarKey)) {
    return true;
  } else {
    return false;
  }
};

export const setCountrySelectorDefaultValue = () => {
  const currentLanguage = getLanguageFromBrowser();
  switch (currentLanguage) {
    case "sv":
      return "Sverige";
    case "da":
      return "Danmark";
    default:
      return "Norge";
  }
};

export const setCurrencyDefaultValue = () => {
  const currentLanguage = getLanguageFromBrowser();
  switch (currentLanguage) {
    case "sv":
      return "SEK";
    case "da":
      return "DKK";
    default:
      return "NOK";
  }
};

export const hasProductsForLanguage = (productCategory, websiteLanguage) => {
  if (websiteLanguage === "no" || websiteLanguage === "en") {
    return true;
  } else if (websiteLanguage === "sv") {
    for (let i = 0; i < productCategory.length; i++) {
      if (productCategory[i].swedishDescription !== null) {
        return true;
      }
    }
  } else if (websiteLanguage === "da") {
    for (let i = 0; i < productCategory.length; i++) {
      if (productCategory[i].danishDescription !== null) {
        return true;
      }
    }
  }
  return false;
};
